import React, { useEffect, useContext } from "react";
import { scrollTo } from "../Utils";
import Intro1 from "./sections/Intro1";
import TopBar1 from "./sections/TopBar1";
import Services1 from "./sections/Services1";
import Portfolio1 from "./sections/Protfolio1";
import Testimonial1 from "./sections/Testimonial1";
import CallToAction1 from "./sections/CallToAction1";
import Pricing1 from "./sections/Pricing1";
import Contact1 from "./sections/Contact1";
import Footer1 from "./sections/Footer1";
import { FyneContext } from "../FyneContext";

export const CleanerLanding = ({
  ...props
}) => {
  
  useEffect(()=>{
    scrollTo('root');
  }, [])

  const config = useContext(FyneContext);
  console.log('CleanerLanding', {props,config});

  return (
//    <FyneworksContext.Consumer>
//      {
//        config=>{
//          console.log('Landing1', {props,config});
//          return (
            <div className="landing">
              <TopBar1 />
              <Intro1 />
              <Portfolio1 />
              <Services1 />
              <Testimonial1 />
              <CallToAction1 />
              {/* <Pricing1 /> */}
              <Contact1 />
              <Footer1 />
            </div>
//          )
//        }
//      }
//    </FyneworksContext.Consumer>
  );
}

export default CleanerLanding;
