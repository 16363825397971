import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";

import Theme from "./Theme";
import CleanerLanding from "./components/CleanerLanding";
import "./styles/sass/app.scss";

import { FyneContext, context } from "./FyneContext";
import { useFyneAPI, FyneProvider } from "@fyne/ui/api";

export const App = () => {
  const { data:config, response, loading } = useFyneAPI(`/config`, [] );
  console.log('Render App', config, response, loading );

  return ( !!loading ? 'Loading' : (
      <MuiThemeProvider theme={Theme}>
        <FyneContext.Provider value={{...context, ...config}}>
          <FyneProvider>
            <Router basename="/">
              <Switch>
                
                <Route path="/" component={CleanerLanding} exact />
                {/* <Route path="/" component={Demo} exact /> */}
                {/* <Route path="/landing1" component={Landing1} /> */}
                {/* <Route path="/landing2" component={Landing2} /> */}
                {/* <Route path="/landing3" component={Landing3} /> */}

                {/* <Route component={Error} /> */}

              </Switch>
            </Router>
          </FyneProvider>
        </FyneContext.Provider>
      </MuiThemeProvider>
    )
  );
}

export default App;
