import React, { useContext, Component } from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import TodayIcon from '@material-ui/icons/Today';
import { FyneContext } from "../../FyneContext";

export const Intro1 = ({...props}) => {// = class Intro1 extends Component {
  const config = useContext(FyneContext);
  console.log('CleanerLanding Intro1', {props,config});

  let state = { };
  
  
  //render() {
    return (
      <section className="section section-intro1" id="intro1"
      style={{background: 'url('+config.APP_BASE+'assets/images/home-bg.jpg) center center/cover no-repeat'}}>
        <div className="container">
          <Grid container spacing={24} justify="center">
            <Grid item md={6}>
              <h1 className="section-intro1__title">
                Fyne Cleaners
              </h1>
              <div className="section-intro1__subtitle">
                Not sure about Pro? Try trial first!
              </div>

              <div className="section-intro1__list">
                <div className="section-intro1__list__item">
                  <Icon color="secondary">check</Icon> Unlimited Projects.
                </div>
                <div className="section-intro1__list__item">
                  <Icon color="secondary">check</Icon> Unlimited Team Members.
                </div>
                <div className="section-intro1__list__item">
                  <Icon color="secondary">check</Icon> Unlimited Disk Space.
                </div>
              </div>

              <div>
                <Fab
                  variant="extended"
                  size="large"
                  color="primary"
                  aria-label="Buy"
                  className="btn-action m-8 open-booking-dialog"
                  //onClick={()=>window.fwx.fyne.general.show()}
                >
                  {/* <Icon className="mr-16">flight_takeoff</Icon> */}
                  <TodayIcon className="mr-16 "/>
                  Book Now
                </Fab>

                <Fab
                  variant="extended"
                  size="large"
                  aria-label="Download"
                  className="btn-action btn-white m-8 open-general-dialog"
                  //onClick={()=>window.fwx.fyne.booking.show()}
                >
                  <Icon className="mr-16">alarm_on</Icon>
                  14 Schedule regular Visits
                </Fab>
              </div>
            </Grid>
            <Grid item md={6} >
              <div className="section-intro1__product">
                <a href="#" className="section-intro1__product__link" target="_blank" rel="noopener noreferrer">
                  <span className="price__text">Only</span>
                  <div className="price">£10</div>
                  <span className="price__text">per hour</span>
                </a>
                <img src="https://res.cloudinary.com/fynewo/image/upload/v1589021345/landing/cleaner/services-details-2.jpg" alt=""/>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  //}
}

export default Intro1;
